@import "../variables.modules";

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  h1 {
    font-size: 2rem;
  }
  img {
    filter: drop-shadow(1rem 1rem 1rem rgba(0, 0, 0, 0.4));
  }
  @media only screen and (min-width: 940px) {
    grid-template-columns: repeat(2, auto);
    h1 {
      font-size: 2rem;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem;

  .image {
    width: 500px;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
}

.source {
  color: $white;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}
