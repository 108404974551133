@import "../variables.modules";

h1 {
    font-size: 2.5rem;
    text-align: center;
    @media only screen and (min-width: 940px) {
      font-size: 3rem;
      text-align: left;
    }
  }

 .block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem;

  .image {
    width: 500px;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
}

.source {
  color: $white;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}
