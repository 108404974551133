@import "../variables.modules";

.terminal {
  width: 80%;
  margin: 2rem auto 4rem auto;
  border-radius: 0.5rem;

  @media only screen and (min-width: 940px) {
    width: 50%;
    margin: 3rem auto 6rem auto;
  }
}

.topRow {
  background-color: #bdc6ce;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 1rem;

  @media only screen and (min-width: 940px) {
    font-size: 1.3rem;
  }

  i {
    padding-left: 0.5rem;
  }
}

.red {
  color: #ff6057;
}

.amber {
  color: #ffbd2e;
}

.green {
  color: #27c93f;
}

.window {
  padding: 1rem 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: $dark;
  color: $white;
  @media only screen and (min-width: 940px) {
    padding: 2rem 3rem;
  }
  p,
  li {
    font-size: 6vw;
    font-family: "Courier New", Courier, monospace;
    margin: 1.3rem 0;

    @media only screen and (min-width: 940px) {
      font-size: 1.8rem;
      margin: 2rem 0;
    }
  }

  #icon-svg {
    color: #fff;
  }
}

.emoji {
  margin-right: 1rem;
}
