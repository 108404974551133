@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap);
.App_shadowed__3jrTB{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}body{font-family:'Roboto', Helvetica, sans-serif;font-size:1.5vh;width:100%;height:100%;color:#1e192b;box-sizing:border-box}*{margin:0;padding:0;box-sizing:border-box}a,a:link,a:hover,a:visited,a:active{text-decoration:none}img{max-width:100%}ul{list-style-type:none}

.BaseLayout_shadowed__1g7o7{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}.BaseLayout_light__1z66x{background-color:#f8f8f8;color:#1e192b;transition:all 700ms}.BaseLayout_dark__2BUQc{background-color:#1f1b28;color:#f8f8f8;transition:all 700ms}.BaseLayout_dark__2BUQc a,.BaseLayout_dark__2BUQc i{color:#f8f8f8;transition:color 700ms}.BaseLayout_dark__2BUQc i:hover{color:grey}.BaseLayout_dark__2BUQc footer{color:#f8f8f8}.BaseLayout_dark__2BUQc>div div:first-child{background-color:#575e64}.BaseLayout_dark__2BUQc>div div:first-child i:first-child{color:#ff6057}.BaseLayout_dark__2BUQc>div div:first-child i:nth-child(2){color:#ffbd2e}.BaseLayout_dark__2BUQc>div div:first-child i:nth-child(3){color:#27c93f}.BaseLayout_dark__2BUQc>div div{background-color:#dee6ee;color:#1e192b}.BaseLayout_dark__2BUQc>div div i{color:slategrey}.BaseLayout_container__2hXED{display:flex;flex-direction:column;min-height:100vh}footer{display:flex;flex-direction:column;justify-content:flex-end;align-items:center;padding:1.5rem 0 1rem 0;opacity:0.5}

.Navbar_shadowed__3WL2c{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}.Navbar_navList__RwM-9{display:grid;grid-template-columns:repeat(5, auto);max-width:90%;justify-items:center;align-items:center;border-bottom:1px solid #e4e4e4;text-transform:lowercase;margin:0 auto;font-size:1rem}@media only screen and (min-width: 940px){.Navbar_navList__RwM-9{max-width:60%}}.Navbar_navList__RwM-9 img{height:60px}.Navbar_navList__RwM-9 a{color:#1e192b;padding:0.5rem 0}.Navbar_navList__RwM-9 li{padding:0.5rem 0}.Navbar_logo__3TDue{color:#1e192b;font-size:2rem;font-weight:bold}.Navbar_dark__1Fw6d a{color:#f8f8f8}.Navbar_dark__1Fw6d .Navbar_topRow__3z73h{background-color:#575e64}.Navbar_dark__1Fw6d .Navbar_logo__3TDue{color:#f8f8f8}

.Toggler_toggler__19B26{font-size:1.8rem;cursor:pointer}

.Home_shadowed__Lv4pJ{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 1025px){main{flex-direction:row;justify-content:center}}main h1{font-size:2.5rem;text-align:center}@media only screen and (min-width: 940px){main h1{font-size:3rem;text-align:left}}main h2{font-size:1.5rem;text-align:center}@media only screen and (min-width: 940px){main h2{font-size:2rem;text-align:left}}main ul{padding:0.8rem}main li{font-size:1.2rem;line-height:1.8}main .Home_firstName__BS-EW{background:-webkit-linear-gradient(135deg, #8d53ff, #ca6be6);-webkit-background-clip:text;-webkit-text-fill-color:transparent}main .Home_emoji__3d6jG{margin-right:0.5rem;font-size:1.5rem}@media only screen and (min-width: 940px){main .Home_emoji__3d6jG{margin-right:1rem}}.Home_avatar__23mjw{width:30vh;height:30vh;border-radius:50%;padding:0.5rem;opacity:0.9;transition:opacity 700ms;margin-bottom:1rem}@media only screen and (min-width: 940px){.Home_avatar__23mjw{width:50vh;height:50vh;margin-right:3rem;margin-bottom:none}}.Home_avatar__23mjw:hover{opacity:1}.Home_mainImg__v5IGL{-webkit-filter:grayscale(1);filter:grayscale(1);border-radius:50%;width:100%}.Home_socials__3Jz0X{display:flex;justify-content:space-evenly;font-size:2.5rem;padding:1rem 0}.Home_socials__3Jz0X a{color:#1e192b;transition:color 700ms}.Home_socials__3Jz0X a:hover{color:grey}

.About_shadowed__qycFA{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}.About_terminal__1FszD{width:80%;margin:2rem auto 4rem auto;border-radius:0.5rem}@media only screen and (min-width: 940px){.About_terminal__1FszD{width:50%;margin:3rem auto 6rem auto}}.About_topRow__hDdE1{background-color:#bdc6ce;color:white;padding:0.5rem;border-radius:0.5rem 0.5rem 0 0;font-size:1rem}@media only screen and (min-width: 940px){.About_topRow__hDdE1{font-size:1.3rem}}.About_topRow__hDdE1 i{padding-left:0.5rem}.About_red__3rPe9{color:#ff6057}.About_amber__148FQ{color:#ffbd2e}.About_green__21wCl{color:#27c93f}.About_window__16mjn{padding:1rem 2rem;border-radius:0 0 0.5rem 0.5rem;background-color:#1e192b;color:#f8f8f8}@media only screen and (min-width: 940px){.About_window__16mjn{padding:2rem 3rem}}.About_window__16mjn p,.About_window__16mjn li{font-size:6vw;font-family:"Courier New", Courier, monospace;margin:1.3rem 0}@media only screen and (min-width: 940px){.About_window__16mjn p,.About_window__16mjn li{font-size:1.8rem;margin:2rem 0}}.About_window__16mjn #About_icon-svg__25bGO{color:#fff}.About_emoji__1gAYL{margin-right:1rem}

.Portfolio_shadowed__1bWvY{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}.Portfolio_projectsGrid__2UtkP{display:grid;grid-template-columns:repeat(1, auto);grid-gap:1rem;margin-bottom:2rem}.Portfolio_projectsGrid__2UtkP h1{font-size:2rem}.Portfolio_projectsGrid__2UtkP img{-webkit-filter:drop-shadow(1rem 1rem 1rem rgba(0,0,0,0.4));filter:drop-shadow(1rem 1rem 1rem rgba(0,0,0,0.4))}@media only screen and (min-width: 940px){.Portfolio_projectsGrid__2UtkP{grid-template-columns:repeat(2, auto)}.Portfolio_projectsGrid__2UtkP h1{font-size:2rem}}.Portfolio_block__2XiVJ{display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;margin:1rem}.Portfolio_block__2XiVJ .Portfolio_image__2WipH{width:500px;border-radius:1rem;margin-bottom:2rem}.Portfolio_source__3BZuy{color:#f8f8f8;text-decoration:none;font-size:1.5rem;padding:1rem;border-radius:0.5rem;box-shadow:0.2rem 0.2rem 0.5rem rgba(0,0,0,0.4);margin-top:1rem}

.Blog_shadowed__34WnT{box-shadow:0 1rem 2rem rgba(0,0,0,0.4)}main{display:flex;align-items:center;flex-direction:column;justify-content:space-evenly;flex:1 1;font-size:1.5rem;position:relative;z-index:1;width:90vw;margin:1rem auto}@media only screen and (min-width: 940px){main{flex-direction:row;justify-content:center}}h1{font-size:2.5rem;text-align:center}@media only screen and (min-width: 940px){h1{font-size:3rem;text-align:left}}.Blog_block__20Vd-{display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;margin:1rem}.Blog_block__20Vd- .Blog_image__2ephW{width:500px;border-radius:1rem;margin-bottom:2rem}.Blog_source__3pgTl{color:#f8f8f8;text-decoration:none;font-size:1.5rem;padding:1rem;border-radius:0.5rem;box-shadow:0.2rem 0.2rem 0.5rem rgba(0,0,0,0.4);margin-top:1rem}

